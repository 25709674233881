import Layout from '../layouts/layout';

const NotFoundPage = () => {
  return (
    <Layout title="Perdidos">
      <section>No pudimos encontrar la página que buscas</section>
    </Layout>
  );
};

export default NotFoundPage;
